import React, {useEffect, useState} from "react";
import { gql, useQuery, useMutation } from '@apollo/client'
import { makeStyles, useTheme, theme } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import Grid from "@material-ui/core/Grid"
import Button from '@material-ui/core/Button';
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery"


const DELETE_QUESTION= gql`
mutation editQuestion($id: Int!, $status:QuestionState!){
  editQuestion(id:$id, status: $status) {
    id,
    status
  }
}`

const GET_QUESTIONS = gql`
query getQuestions{
  getQuestions{
    id
    updatedContent
    content
    from
    status
    createdAt
    updatedAt

  }
}`
const useStyles = makeStyles({
buttonMain:{
  width: 191,
  height: 68
  }
})

function DeleteAllQuestions(){
  const classes = useStyles();
  const matches = useMediaQuery('min-width:300px');

  const {error, loading, data} = useQuery(GET_QUESTIONS);
  const [getData, setData] = useState()
  const [open, setOpen] = React.useState(false);
  const [deleteQuestion] = useMutation(DELETE_QUESTION)


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(()=>{
    if(data){
      setData(data.getQuestions)
    }
  },[data])
  return (
    <div>
    <Button className={classes.buttonMain}
    size="large" variant="contained" color="secondary"
    onClick={handleClickOpen}>
    Alle Inhalte löschen
    </Button>
    <Dialog
    fullWidth
    maxWidth={"sm"}
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">Alle Inhalte löschen?</DialogTitle>
    <DialogContent>

    <DialogContentText id="alert-dialog-description">


    </DialogContentText>
    </DialogContent>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">

    </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Box flexGrow={1} >
    <Button size="large" variant="outlined" onClick={
      (e=>{
       e.preventDefault()
      getData.map((val)=>{
        deleteQuestion({
          variables:{
            id: val.id,
            status:"INACTIVE"
          }
        })
      }
    )
  handleClose()}
  )}
    >Ja
    </Button>
    </Box>
    <Box>
    <Button size="large" variant="outlined" onClick={handleClose}
    >
    Nein
    </Button>
    </Box>
    </DialogActions>
    </Dialog>
    </div>
  );
}


export default DeleteAllQuestions;
