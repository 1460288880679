import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuthState } from '../context/auth'

export default function DynamicRoute(props) {
    const { user } = useAuthState() // CHANGE AUTHSTATE. GET USER INFO AFTER LOGIN CAUSE THERE ARE NO

    
    if (props.guest && user) {
        return <Redirect to={{ pathname: '/' }} />
        
    }
    if (props.authenticated && !user) {
        return <Redirect to={{ pathname: '/login' }} />
    }

    if (props.authenticated && props.neededRole && user.roleCode > props.neededRole) {
        return <Redirect to={{ pathname: '/' }} />
    }

   
    return <Route component={props.component}{...props} />


} 
