import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from "@material-ui/core/Button"
import CardActionArea from "@material-ui/core/CardActionArea"

const useStyles = makeStyles((theme) => ({
  root: {
    height: 220,
    width: 160

  },
  media: {
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontSize: 8
  },
  cardHeaders:{
  },
  buttons:{
    fontSize: 10
  },
  cardactions:{
    paddingTop: 0,
    paddingBottom: 0
  },
  typograhizzle:{
    fontSize: 6
  },
  cardcontents:{
    paddingTop: 0,
    paddingBottom: 0
  }
}));

function ChooseOption1() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>

    <CardMedia
    className={classes.media}
    height="220"
    image="http://via.placeholder.com/400x200"
    title="Paella dish"
    />
    </Card>
  );
}


function ChooseOption2() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
    <CardActionArea>
    <CardMedia
    component="img"
    alt="pizza"
    height="120"
    image="http://via.placeholder.com/400x200"
    title="Contemplative Reptile"
    />
    <CardContent className={classes.cardcontents}>
    <Typography gutterBottom variant="p" component="h2">
    Pizza
    </Typography>
    <Typography variant="p" color="textSecondary" component="p" style={{fontSize:8}}>
    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
    across all continents except Antarctica
    </Typography>
    </CardContent>
    </CardActionArea>
    <CardActions className={classes.cardactions}>
    <FavoriteIcon className={classes.buttons}/>
    <ShareIcon className={classes.buttons}/>
    </CardActions>
    </Card>
  );
}

function ChooseOption3() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
    <CardContent>
    <Typography className={classes.title} color="textSecondary" gutterBottom>
    Word of the Day
    </Typography>
    <Typography variant="h5" component="h2">
    be{bull}nev{bull}o{bull}lent
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
    adjective
    </Typography>
    <Typography variant="body2" component="p">
    well meaning and kindly.
    <br />
    {'"a benevolent smile"'}
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
    Learn More
    </Typography>
    </CardContent>
    </Card>
  );
}

export {ChooseOption1, ChooseOption2, ChooseOption3}
