import { gql, useQuery } from '@apollo/client';
import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { useAuthDispatch } from '../../../context/auth';




const SWITCH_COMPANY_CONTEXT = gql`
query switchCompanyContext($companyCode: String!){
    switchCompanyContext(companyCode:$companyCode) {
      token,
      userId,
      email, 
      role,
      roleCode, 
      companyCode
  }
}`



export default function CompanysContextSwitch({ companyCode }) {
  const history = useHistory();
  const authDispatch = useAuthDispatch()

  const { loading, error, data } = useQuery(SWITCH_COMPANY_CONTEXT, {
    variables: { companyCode }
  });

  useEffect(() => {
    
    
    if(data && data.switchCompanyContext){
      authDispatch({ type: 'SWITCH_CONTEXT', payload: data.switchCompanyContext })
      history.push("/" + data.switchCompanyContext.companyCode + "/")
    }
   
  }, [data]);

 

  if (loading) return null;
  if (error) return null ;

  
  return null

}
