import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';



const useStyles = makeStyles((theme) => ({
  
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
    

}));



export default function FormDialog({ open, onClose , onFinish}) {
    const classes = useStyles();

    return <Backdrop open={true} className={classes.backdrop}>
    <CircularProgress color="inherit" />
  </Backdrop>

}
