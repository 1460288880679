import { gql, useQuery, useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from "@material-ui/core/Grid"
import Grow from '@material-ui/core/Grow'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Link } from "react-router-dom"

import Icon from '@material-ui/core/Icon';
import MaterialTable from 'material-table';
import { materialtable } from '../../../util/language';
import LoadingScreen from '../../dialogs/Loading';
import Surface from '../../MainSurface';
import DataGridToolbar from '../../DataGridToolbar';
import { DataGrid } from '@material-ui/data-grid';
import { datagrid } from '../../../util/language';
import { useHistory } from "react-router-dom";


const getQandARooms = gql`
query getQandARooms{
  getQandARooms{
        id: roomId,
        roomId

    }
}`






export default function Stages(props) {

    const { error, loading, data, refetch } = useQuery(getQandARooms,
        {
          fetchPolicy: "network-only"
        })
        let elements = []
        const forceUpdate = useForceUpdate();
        let history = useHistory();
        const [openDialog, setDialogOpen] = React.useState(false);
        const [selection, setSelection] = React.useState(null);
      

        const columns= [

          { field: "id", headerName: "id", hide: "fasle", type: "int" },
          { field: "roomId", headerName: "roomId", type: "string" },
         
          
        ];

    


        function useForceUpdate(){
          const [value, setValue] = React.useState(0); // integer state
          return () => setValue(value => value + 1); // update the state to force render
        }
        
        const handleAddProject = () => {
          setDialogOpen(true);
        };
        
        
        function currentlySelected(selections) {
          history.push("/gls/gls/qanda/"+selections.data.roomId)
        }


        if (error) return `Error! ${error}`;
      
        
        if(data){
      console.log(data.getQandARooms)
          return (
            <>
        
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                   
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ widht: '100%' }}>
                  <DataGrid rows={data.getQandARooms} columns={columns} components={{
                Toolbar: DataGridToolbar,
              }} 
              componentsProps={{toolbar: {filter: true , remove: false, add: handleAddProject, edit: false }}}
              pageSize={100}   autoHeight hideFooterRowCount='false' 
              localeText={datagrid}
              onRowSelected={currentlySelected}
              />
                  </Grid>
                </Grid>
        
              
              {(loading) && <LoadingScreen/> }
            </>
          )
        }

        return null
      
      


}

