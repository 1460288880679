import {React, useState,useEffect} from "react"
import CreateCard from "./CreateCard"
import Customization from "./Customization"
import Grid from "@material-ui/core/Grid"
import {CardOption1, CardOption2, CardOption3} from "./DisplayedCards"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export default function ConversionCard(){

  const [state, setState] = useState({
    value:"",
  })
  const [cardOption, setCardOption] =useState({
    value:""
  })
  const [color, setColor] = useState({
    value:""
  })

  const handleChange = (props) => {
    setState({value:props})
  };

  const optionHandler = (props) => {
      setCardOption({value:props})
  };

  const colorHandler = (props) => {
    setColor({value:props})
  }

  console.log("hello :" +color.value)

  let cardOptions = <CardOption1 value={state.value} sndValue={color.value}/>

  if(cardOption.value.option==="third"){
    cardOptions =<CardOption3 value={state.value} sndValue={color.value}/>
  }else if(cardOption.value.option==="second"){
    cardOptions=<CardOption2 value={state.value} sndValue={color.value}/>
  }else {
    cardOptions=<CardOption1 value={state.value} sndValue={color.value}/>
  }

  const matches = useMediaQuery('(max-width:1300px)');


  return(
    <div style={{marginTop:"50px"}}>
    <Grid container>
    <Grid container spacing={7} item xs={12} sm={12} md={12} lg={6} xl={6}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <CreateCard parentHandleChange={handleChange} parentOptionHandler={optionHandler} cardOption={cardOption}/>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <Customization parentColorHandler={colorHandler}/>
    </Grid>
    </Grid>
    <Grid container justify="center" item xs={12} sm={12} md={12} lg={6} xl={6}>
    <Grid
    style={(`${matches}`==="true")? {position:"static", paddingTop: 50, margin: "auto"}:{position:"fixed"}}
    item xs={12} sm={12} md={6} lg={6} xl={6} container justify="center">
    {cardOptions}
    </Grid>
    </Grid>
    </Grid>
    </div>
  )
}
