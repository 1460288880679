import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import CardActionArea from '@material-ui/core/CardActionArea';
import paella from "./images/paella.jpg"


const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
  },
  mainGrid:{
    border:"1px solid #d9d9d9",
    borderRadius:5,
    padding: 60,
  },
  media: {
    height:220 ,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function CardOption1(value) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
    <Grid className={classes.mainGrid}>
    <Grid container justify="center" item xs={12}>
    <Typography variant="h3" component="h1" color="primary">Preview</Typography>
    </Grid>
    <Card className={classes.root}>
    <CardMedia
    className={classes.media}
    height="250"
    image="http://via.placeholder.com/400x200"
    title="Paella dish"
    component="img"
    />

    </Card>
    </Grid>
    </div>
  );
}

function CardOption2(value) {
  const classes = useStyles();
  return (
    <Grid className={classes.mainGrid}>
    <Grid container justify="center" item xs={12}>
    <Typography variant="h4" component="h1" color="primary">Vorschau</Typography>
    </Grid>
    <Grid container justify="center" item xs={12}>
    <Card className={classes.root}>
    <CardActionArea>
    <CardMedia
    component="img"
    alt="pizza"
    height="250"
    image={paella}
    title="Contemplative Reptile"
    />
    <CardContent>
    <Typography gutterBottom variant="h3" component="h2" style={{color:"#"+value.sndValue.title}}>
    {value.value.title}
    </Typography>
    <Typography gutterBottom variant="h5" component="h2" style={{color:"#"+value.sndValue.content}}>
    {value.value.content}
    </Typography>
    </CardContent>
    </CardActionArea>
    <CardActions>
    <Button size="large" color="inherit" variant="outlined" style={{color:"#"+value.sndValue.buttonText}}>
    {value.value.buttonText}
    </Button>
    </CardActions>
    </Card>
    </Grid>
    </Grid>
  );
}

function CardOption3(value) {
  const classes = useStyles();

  return (
    <Grid className={classes.mainGrid}>
    <Grid container justify="center" item xs={12}>
    <Typography variant="h4" component="h1" color="primary">Vorschau</Typography>
    </Grid>
    <Grid container justify="center" item xs={12}>
    <Card className={classes.root}>
    <CardContent>
    <Typography variant="h5" component="h2">
    {value.value.title}
    </Typography>
    <Typography variant="body2" component="p">
    {value.value.content}
    </Typography>
    </CardContent>
    <CardActions>
    <Button size="small">{value.value.buttonText}</Button>
    </CardActions>
    </Card>
    </Grid>
    </Grid>
  );
}


export {CardOption1, CardOption2,CardOption3 }
