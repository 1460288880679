import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import { gql, useQuery, useMutation } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles({
});

const DELETE_QUESTION = gql`
mutation editQuestion($id: Int!, $status:QuestionState!){
  editQuestion(id:$id, status: $status) {
    id,
    status
  }
}`

export default function DeleteMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteQuestion] = useMutation(DELETE_QUESTION)



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div >
    <IconButton  color="secondary"
    aria-controls="simple-menu"
    aria-haspopup="true"
    onClick={handleClick}
    >
    <DeleteIcon/> Löschen
    </IconButton>
    <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}
    >
    <Button size="large" color="secondary" open={anchorEl} onClick={((e)=>{
      e.preventDefault();
      if(props.value.status=="ACTIVE" || props.value.status=="PINNED"){
        deleteQuestion({
          variables:{id: props.value.id,
            status:"INACTIVE"
          }
        })
      handleClose()
    }
      })}>
       Löschen!
      </Button>
      </Menu>
      </div>
    );
  }
