import { gql, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import MaterialTable from 'material-table';
import React, { useState } from 'react';
import { materialtable } from '../../../util/language';
import DialogCreateCompany from '../../dialogs/DialogCreateCompany';
import LoadingScreen from '../../dialogs/Loading';
import Surface from '../../MainSurface';
import CompanysContextSwitch from './CompanyContextSwitch';
import DataGridToolbar from '../../DataGridToolbar';
import { DataGrid } from '@material-ui/data-grid';
import { datagrid } from '../../../util/language';

const GET_COMPANYS = gql`
query getCompanys{
  getCompanys{
    id,
    companyName,
    companyCode,
    createdAt,
  }
}`




const columns= [

  { field: "id", headerName: "id", hide: "true", type: "int",filterable: false },
  { field: "companyCode", headerName: "Code", type: "string", flex: 1.5, },
  { field: "companyName", headerName: "Organisation", type: "dateTime", flex: 0.8, },  
  { field: "createdAt", headerName: "Angelegt", type: "dateTime", flex: 0.8, },  
  
];



function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}


export default function Companys(props) {
  let companys = []
  const forceUpdate = useForceUpdate();

  const [openDialog, setDialogOpen] = React.useState(false);
  const [selection, setSelection] = React.useState(null);


  const { loading, error, data, refetch, networkStatus } = useQuery(GET_COMPANYS, {   
    fetchPolicy: "network-only"
  });


  function currentlySelected(selections) {
    setSelection(selections)
  }



  const handleAddProject = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };
  const handleSubmit = () => {
    setDialogOpen(false);
    refetch()
    forceUpdate()
  };

  
  
  if (error) return `Error! ${error}`;

  
  if(data){
     companys = data.getCompanys.map((item) => 
    Object.assign({}, item, {selected:false})
)

  }

  

    return (
      <>
        <Surface>
  
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon>add</Icon>}
                onClick={handleAddProject}
              >
                Neue Organisation erstellen
        </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ widht: '100%' }}>
            <DataGrid rows={companys} columns={columns} components={{
          Toolbar: DataGridToolbar,
        }} 
        componentsProps={{toolbar: {filter: true , remove: false, add: handleAddProject, edit: false }}}
        pageSize={100}   autoHeight hideFooterRowCount='false' 
        localeText={datagrid}
        onRowSelected={currentlySelected}
        />
            </Grid>
          </Grid>
        </Surface>
        <DialogCreateCompany open={openDialog} onClose={closeDialog} onFinish={handleSubmit} />
  
        {selection && <CompanysContextSwitch companyCode={selection.data.companyCode} />}
        {(loading|| networkStatus==4) && <LoadingScreen/> }
      </>
    )
  

}
