import React, {useEffect, useState} from "react";
import { gql, useQuery, useMutation } from '@apollo/client'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import grey  from "@material-ui/core/colors/grey";
import EditIcon from "@material-ui/icons/Edit";
import PinDropIcon from '@material-ui/icons/PinDrop';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import EditQuestion from "./EditQuestion"
import DeleteQuestion from "./DeleteQuestion"
import Divider from "@material-ui/core/Divider";
import useMediaQuery from "@material-ui/core/useMediaQuery"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";


const fontawe = <PinDropIcon/>


const EDIT_QUESTION=gql`
mutation editQuestion($id: Int!,$updatedContent:String! $status:QuestionState!){
  editQuestion(id:$id,updatedContent:$updatedContent, status: $status) {
    id,
    updatedContent,
    status
  }
}`


const GET_QUESTIONS = gql`
query getQandA($roomId: Int!){
  getQandA(roomId:$roomId){
    id: qandaId
    updatedContent
    content
    from: author
    status
    updatedAt
    createdAt

  }
}`

const ADD_PIN = gql`
mutation QandAaddPin($id: Int!, $status:String!){
  QandAaddPin(qandaId:$id, status: $status) 
}`

const useStyles = makeStyles({
  root: {
    margin:"auto",
    backgroundColor:"#f8f9fa"
  },
  pinned:{
    backgroundColor:"#f8f9fa",
    border: '3px solid #3f51b5',
  },
  expandWidth:{
    width:960
  },
  deleted:{
    display: "none"
  },
  content:{
    wordWrap: "break-word",
    marginBottom:25,
    marginTop:10
  },
  updatedContent:{
    wordWrap: "break-word",
    marginBottom:20,
  },
  contentIfUpdatedTrue:{
    marginbottom:20,
    wordWrap: "break-word",
    color:"#757575"
  },
  header:{
    wordWrap: "break-word",
    fontWeight:450
  },
  cardActions:{
    display: 'flex',
    flexDirection: 'row', //oder 'row'

  },
  cardActionsXs:{
    display: 'flex',
    flexDirection:"column-reverse",

  },
  typoMargin:{
    marginTop:20
  },
  pinnedButton:{
    fontWeight:"bold"
  }

});

function returnTimestamp(stringTimestamp){

  const parsed = parseInt(stringTimestamp, 10)
  let a = new Date(parsed);
  const months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  let month = months[a.getMonth()];
  let date = a.getDate();
  let hour = a.getHours();
  let min = a.getMinutes();
  let sec = a.getSeconds();
  if(hour <10) {
    hour="0"+hour
  }else if(min <10){
    min="0"+min
  }else if(sec<10){
    sec="0"+sec
  }
  let time = date + '.' + month + ' | ' + hour + ':' + min + ':' + sec ;
  return time;
}

function returnContentUpdatedTrue(props){
  const updatedContent = props.updatedContent
  if(updatedContent!=null){
    return "Original:"
  }
}

function returnUpdatedContent(props){

  const updatedContent = props.updatedContent
  if(updatedContent!=null){
    return "Bearbeitet:"
  }
  return props.updatedContent
}

function GetQuestions(){

  let { id } = useParams();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const matchesXs = useMediaQuery('(min-width:600px)')
  const matchesButtons = useMediaQuery('(min-width:620px)')
  const matchesTime = useMediaQuery('(min-width:720px)')
  const classes = useStyles();
  const {error, loading, data} = useQuery(GET_QUESTIONS, {variables: {roomId: parseInt(id)}});
  const [addPin] = useMutation(ADD_PIN)
  const [editQuestion] = useMutation(EDIT_QUESTION)
  const [getData, setData] = useState([])

  useEffect(()=>{
    if(data){
      setData(data.getQandA)
    }
  },[data]);
  return(
    <div>
    {getData.map((val)=>{
      let convertedTime = returnTimestamp(val.createdAt)
      return(
        <Grid
        container
        xs={"12"}
        sm={"12"}
        md={"12"}
        lg={"12"}
        xl={"12"}
        spacing={7}
        direction="column"
        alignItems="center"
        justify="flex-start"
        overflow="visible"
        style={{"margin":"auto"}}
        >
        <Grid item xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"} className={classes.expandWidth} style={{paddingLeft: 0, paddingRight:0}} >
        <Card raised={"true"} className={(val.status=="INACTIVE" ? classes.deleted: val.status=="pinned" ? classes.pinned : classes.root)}>
        <CardContent>
        <Grid container
        justify="space-between"
        alignItems="baseline"
        direction={(`${matchesTime}`=="true")?"row":"column-reverse"}
        >
        <Grid item xs={"12"} sm={(`${matchesTime}`=="true")?"8":"12"} md={"9"} lg={"9"} xl={"9"}>
        <Typography className={classes.header} gutterBottom variant={(`${matchesXs}`=="true")?"h4":"h3"} component="h2">
        {val.from}
        </Typography>
        </Grid>
        <Grid item xs={"12"} sm={(`${matchesTime}`=="true")?"4":"0"} md={"3"} lg={"3"} xl={"3"}>
        <Typography className={classes.header} gutterBottom variant="h5" component="h2" style={{"color":"#252323"}}>
        {convertedTime}
        </Typography>
        </Grid>
        </Grid>
        <Divider style={{"backgroundColor":"grey","border": "1px solid grey"}}/>
        <Typography className={(val.updatedContent!=null)? classes.typoMargin: classes.ablage} variant="body1" component="h2">
        {returnUpdatedContent(val)}
        </Typography>
        <Typography className={(val.updatedContent!=null)? classes.updatedContent : classes.ablage} color="textPrimary" variant="h4" component="h2" >
        {val.updatedContent}
        </Typography>
        <Divider light />
        <Typography className={(val.updatedContent!=null)? classes.typoMargin: classes.ablage} variant="body1" component="h2">
        {returnContentUpdatedTrue(val)}
        </Typography>
        <Typography className={(val.updatedContent!=null)? classes.contentIfUpdatedTrue: classes.content}  variant="h4" component="h2">
        {val.content}
        </Typography>
        </CardContent>
        <CardActions className={((`${matchesButtons}`=="true")? classes.cardActions: classes.cardActionsXs)}>
        <Box flexGrow={1} >
        <DeleteQuestion value={val} />
        </Box>
        <Box>
        <EditQuestion value={val}/>
        </Box>
        <Box>
        <IconButton className={(val.status=="PINNED")?classes.pinnedButton: classes.ablage} size="large" color="primary" onClick={((e)=>{
          e.preventDefault();
          if(val.status=="active"){
            addPin({
              variables:{id:val.id,
                status:"pinned"
              }
            })
          }else{
            addPin({
              variables:{id:val.id,
                status:"active"
              }
            })
          }
        })} >
        {fontawe}
        {(val.status=="pinned" ? "Pin entfernen": "Pin hinzufügen")}
        </IconButton>
        </Box>
        </CardActions>
        </Card>
        </Grid>
        </Grid>
      )
    }
  )
}
</div>
)
}

export default GetQuestions;
