import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import { useAuthDispatch, useAuthState } from '../../context/auth';
import { useNavigationDispatch } from '../../context/navigation';







const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {

    [theme.breakpoints.up('sm')]: {
      width: `calc(100% )`,
      marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  burgerButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbarIcons: {
    marginLeft: theme.spacing(2),
  },
  typography: {
    alignSelf: 'center',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
}));


function ResponsiveDrawer(props) {
  const { user } = useAuthState()
  const authDispatch = useAuthDispatch()

  const navigationDispatch = useNavigationDispatch()

  const classes = useStyles();

  const handleDrawerToggle = () => {
    navigationDispatch({ type: 'TOGGLE_BURGER' })
  };




  const handleLogout = () => {
    authDispatch({type: 'LOGOUT'})
  };


  if (!user) return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Wirkung Plattform
        </Typography>
        </Toolbar>
      </AppBar>
      <br></br>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box display='flex' flexGrow={1}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.burgerButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.typography} noWrap>
              Wirkung Plattform
          </Typography>
          </Box>
            <IconButton
              color="inherit"
              edge="end"
              className={classes.toolbarIcons}
              onClick={handleLogout}
            >
              <Typography variant="button" noWrap>
              Logout
          </Typography>              
            </IconButton>          
        </Toolbar>
      </AppBar>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
