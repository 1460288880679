import { Container } from '@material-ui/core';
import { deDE } from '@material-ui/core/locale';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ApolloProvider from './ApolloProvider';
import './App.scss';
import Router from './components/Navigation/Router';
import { AuthProvider } from './context/auth';
import { NavigationProvider } from './context/navigation';
import { PopupProvider } from './context/popup';






const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, deDE);

function App() {


  return (
    <Fragment>
      <ApolloProvider>
        <AuthProvider>
          <NavigationProvider>
            <BrowserRouter >
              <ThemeProvider theme={theme}>
                <Container className={"feed"} style={{ padding: '0', maxWidth: "100%" }}>

                  <PopupProvider>
                    <Router />
                  </PopupProvider>
                </Container>
              </ThemeProvider>
            </BrowserRouter>
          </NavigationProvider>
        </AuthProvider>
      </ApolloProvider>
    </Fragment>

  );
}

export default App;
