import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import UpdateIcon from '@material-ui/icons/Update';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider,  useTheme } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors'
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles((theme) => ({
    gridRefresh:{
      position:"fixed",
      zIndex: 1,
    },
}));



function RefreshPage() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:300px)');

  function refreshingPage() {
    window.location.reload(false);
  }

  return (
    <div>
    <Grid
    container
    direction="column"
    alignItems="center"
    justify="center"
    overflow="visible"
    >
    <Grid className={classes.gridRefresh}
    item xs={"12"} sm={"12"} md={"12"} lg={"12"} xl={"12"}>
    <Button size="large" variant="contained" color="primary" disableElevation onClick={refreshingPage}>
    <h2>Aktualisieren</h2></Button>
    </Grid>
    </Grid>
    </div>
  );
}

export default RefreshPage;
