
import { gql, useQuery, useMutation } from '@apollo/client'

import Grid from "@material-ui/core/Grid";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Paper from "@material-ui/core/Paper";
import { Container } from '@material-ui/core';



const GET_POSTCARD_WINNER = gql`
query getPostcardWinner{
    getPostcardWinner{
       url
    }
}`
export default function PostCardSlides(){

    const { error, loading, data } = useQuery(GET_POSTCARD_WINNER,
        {
          fetchPolicy: "network-only"
        })



        if(data){
            const handleDragStart = (e) => e.preventDefault();
          
            const items = data.getPostcardWinner.map(element =>
                
                <img src={element.url} style={{width:"100%"}} onDragStart={handleDragStart} />,

            ).reverse()

            return(
                <div>
  <Container maxWidth="lg" style={{backgroundColor: "#fff"}}>
  <Grid container direction="column" justify="center" alignItems="center" spacing={3} style={{ height:"100vh"}}>
        <Grid container item justify="center" xs={12}>
        <AliceCarousel mouseTracking items={items}
                                   animationDuration={500}
                                   keyboardNavigation
                                   disableButtonsControls
                                   disableDotsControls
                                   responsive={true}/>
      </Grid>
        </Grid>
                   
  </Container>

                </div>
            )

        }

        return null
  

}
