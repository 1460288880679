import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Route } from 'react-router-dom';
import { Link, useLocation } from "react-router-dom";




function SimpleBreadcrumbs() {
  return <Route>
    {({ location }) => {
      const pathnames = location.pathname.split('/').filter(x => x);
      return (
        <Breadcrumbs aria-label="Breadcrumb">
          <Link color="inherit" to="/">
            Home
            </Link>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return last ? (
              <Typography color="textPrimary" key={to}>
                {value}
              </Typography>
            ) : (
                <Link color="inherit" to={to} key={to}>
                  {value}
                  
                </Link>
              );
          })}
        </Breadcrumbs>
      );
    }}
  </Route>

}


export default function Surface(props) {
  return (
    <div  style={{display: 'none !important'}}>
  <SimpleBreadcrumbs />   

    </div>

  )

}
