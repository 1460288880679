
    export const datagrid= {
        
            // Root
     rootGridLabel: 'grid',
     noRowsLabel: 'Keine Einträge',
     errorOverlayDefaultLabel: 'An error occurred.',
   
     // Density selector toolbar button text
     toolbarDensity: 'Zeilengröße',
     toolbarDensityLabel: 'Größe',
     toolbarDensityCompact: 'Kompakt',
     toolbarDensityStandard: 'Standard',
     toolbarDensityComfortable: 'Komfortabel',
   
     // Columns selector toolbar button text
     toolbarColumns: 'Spalten',
     toolbarColumnsLabel: 'Spalten auswählen',
   
     // Filters toolbar button text
     toolbarFilters: 'Filter',
     toolbarFiltersLabel: 'Filter anzeigen',
     toolbarFiltersTooltipHide: 'Filter ausblenden',
     toolbarFiltersTooltipShow: 'Filter anzeigen',
     toolbarFiltersTooltipActive: (count) =>
       count !== 1 ? `${count} Aktive Filter` : `${count} Aktive Filter`,
   
     // Export selector toolbar button text
     toolbarExport: 'Exportieren',
     toolbarExportLabel: 'Exportieren',
     toolbarExportCSV: 'Als CSV herunterladen',
   
     // Columns panel text
     columnsPanelTextFieldLabel: 'Spalte finden',
     columnsPanelTextFieldPlaceholder: 'Spalten Titel',
     columnsPanelDragIconLabel: 'Spalte umsortieren',
     columnsPanelShowAllButton: 'Alle anzeigen',
     columnsPanelHideAllButton: 'Alle ausblenden',
   
     // Filter panel text
     filterPanelAddFilter: 'Filter hinzufügen',
     filterPanelDeleteIconLabel: 'Löschen',
     filterPanelOperators: 'Operatoren',
     filterPanelOperatorAnd: 'UND',
     filterPanelOperatorOr: 'ODER',
     filterPanelColumns: 'Spalten',
     filterPanelInputLabel: 'Wert',
     filterPanelInputPlaceholder: 'Wert Filtern',
   
     // Filter operators text
     filterOperatorContains: 'enthält',
     filterOperatorEquals: 'ist gleich',
     filterOperatorStartsWith: 'beginnt mit',
     filterOperatorEndsWith: 'endet mit',
     filterOperatorIs: 'ist',
     filterOperatorNot: 'ist nicht',
     filterOperatorAfter: 'ist nach',
     filterOperatorOnOrAfter: 'ist enthalten oder danach',
     filterOperatorBefore: 'ist davor',
     filterOperatorOnOrBefore: 'ist enthalten oder davor',
   
     // Column menu text
     columnMenuLabel: 'Menü',
     columnMenuShowColumns: 'Spalten anzeigen',
     columnMenuFilter: 'Filter',
     columnMenuHideColumn: 'Ausblenden',
     columnMenuUnsort: 'Unsort',
     columnMenuSortAsc: 'Aufsteigend sortieren',
     columnMenuSortDesc: 'absteigend sortieren',
           
    }    
    
  export const materialtable ={
    body: {
      emptyDataSourceMessage: 'Keine Einträge',
      addTooltip: 'Hinzufügen',
      deleteTooltip: 'Löschen',
      editTooltip: 'Bearbeiten',
      filterRow: {
        filterTooltip: 'Filter'
      },
      editRow: {
        deleteText: 'Diese Zeile wirklich löschen?',
        cancelTooltip: 'Abbrechen',
        saveTooltip: 'Speichern'
      }
    },
    grouping: {
      placeholder: 'Spalten ziehen ...',
      groupedBy: 'Gruppiert nach:'
    },
    header: {
      actions: 'Aktionen'
    },
    pagination: {
      labelDisplayedRows: '{from}-{to} von {count}',
      labelRowsSelect: 'Zeilen',
      labelRowsPerPage: 'Zeilen pro Seite:',
      firstAriaLabel: 'Erste Seite',
      firstTooltip: 'Erste Seite',
      previousAriaLabel: 'Vorherige Seite',
      previousTooltip: 'Vorherige Seite',
      nextAriaLabel: 'Nächste Seite',
      nextTooltip: 'Nächste Seite',
      lastAriaLabel: 'Letzte Seite',
      lastTooltip: 'Letzte Seite'
    },
    toolbar: {
      addRemoveColumns: 'Spalten hinzufügen oder löschen',
      nRowsSelected: '{0} Zeile(n) ausgewählt',
      showColumnsTitle: 'Zeige Spalten',
      showColumnsAriaLabel: 'Zeige Spalten',
      exportTitle: 'Export',
      exportAriaLabel: 'Export',
      exportName: 'Export als CSV',
      searchTooltip: 'Suche',
      searchPlaceholder: 'Suche'
    }
  }
  