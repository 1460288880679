import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  GridFilterToolbarButton, GridToolbarContainer,
  GridToolbarExport
} from '@material-ui/data-grid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';



export default function CustomToolbar({filter, download, remove, upload, mail, add, edit}) {
    return (
      <GridToolbarContainer>
        {filter && <GridFilterToolbarButton />}
        {download && <GridToolbarExport />}
        {remove && <div>
        &nbsp;
        <IconButton size="small">
        <DeleteOutlineIcon style={{ fontSize: 18 }} color="primary"/>
        <Typography color="primary" variant="button" style={{ fontSize: 13 }} >&nbsp;Löschen</Typography>
        </IconButton>
        </div>}      
        {add && <div>
          &nbsp;
        <IconButton size="small" onClick={add}>
        <AddIcon style={{ fontSize: 18 }} color="primary"/>
        <Typography color="primary" variant="button" style={{ fontSize: 13 }} >&nbsp;Hinzufügen</Typography>
        </IconButton>
        </div>}      
        {edit && <div>
          &nbsp;
        <IconButton size="small" onClick={edit}>
        <EditIcon style={{ fontSize: 18 }} color="primary"/>
        <Typography color="primary" variant="button" style={{ fontSize: 13 }} >&nbsp;Bearbeien</Typography>
        </IconButton>
        </div>}   
        {upload && <div>
          &nbsp;
        <IconButton size="small">
        <PublishIcon style={{ fontSize: 18 }} color="primary"/>
        <Typography color="primary" variant="button" style={{ fontSize: 13 }} >&nbsp;Importieren</Typography>
        </IconButton>
        </div>}
        {mail && <div>
          &nbsp;
        <IconButton size="small">
        <MailOutlineIcon style={{ fontSize: 18 }} color="primary"/>
        <Typography color="primary" variant="button" style={{ fontSize: 13 }} >&nbsp;Email senden</Typography>
        </IconButton>
        </div>}
       
      </GridToolbarContainer>
    );
  }