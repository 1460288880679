import React from 'react'
import { Helmet } from 'react-helmet'
import { gql, useQuery } from '@apollo/client'
import GetQuestions from "./GetQuestions"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import RefreshPage from "./RefreshPage"
import AddQuestion from "./AddQuestion"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { makeStyles, useTheme } from "@material-ui/core/styles";
import DeleteAllQuestions from "./DeleteAllQuestions"
const TITLE = 'WIRKUNG'
// import Search from "./Search"


const useStyles = makeStyles({
  aligning:{
    display: 'flex',
    justifyContent: 'flex-end', //oder 'flex-start'
    flexDirection: 'column', //oder 'row'Did you mean: font awesome pin
  }
})


export default function ModerateQuestions(props) {
  const matches = useMediaQuery('(max-width:600px)');
  const classes = useStyles();
  return (
    <>
    <Helmet>
    <title>{TITLE}</title>
    </Helmet>
    <div>
    <Container maxWidth="lg" style={{"margin":"auto", marginTop: "100px"}} >
    <div>
    <Grid container
    spacing={7}
    direction={(`${matches}`=="true")?"column-reverse":"row"}
    alignItems="center"
    justify="space-between"
    overflow="visible"
    style={{marginTop:"auto", marginBottom:"auto"}}>
    <Grid item xs={"12"} sm={"12"} md={"12"} lg={"12"}>
    <RefreshPage />
    </Grid>
    </Grid>
    </div>
    <GetQuestions />
    <br />
    <Grid container
    spacing={(`${matches}`=="true")?2:9}
    justify="center"
    alignItems="baseline"
    direction={(`${matches}`=="true")?"column-reverse":"row"}
    >
    <Grid container justify={(`${matches}`=="true")?"center":"flex-end"}
    item xs={"12"}sm={"6"} md={"6"} lg={"6"}>
    <DeleteAllQuestions />
    </Grid>
    <Grid container justify={(`${matches}`=="true")?"center":"flex-start"} item xs={"12"} sm={"6"} md={"6"} lg={"6"}>
    <AddQuestion />
    </Grid>
    </Grid>
    </Container>
    </div>
    </>
  )
  // }
}
