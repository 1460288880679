import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';

import ImageIcon from '@material-ui/icons/Image';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    action: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        margin: theme.spacing(5),
    },
    switch: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    input: {
        display: 'none',
      },


}));



export default function FormDialog({ open, onClose, onSubmit }) {
    const classes = useStyles();

    const [type, setType] = React.useState('');

    const handleChange = (event) => {
        setType(event.target.value);
    };





    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth={"sm"} >
            <DialogTitle id="form-dialog-title">Neues Asset erstellen</DialogTitle>
            <DialogContent>


                <InputLabel id="type">Asset Typ</InputLabel>
                <Select fullWidth
                    value={type}
                    onChange={handleChange}
                >
                    <MenuItem value={1}>Button</MenuItem>
                    <MenuItem value={2}>Text</MenuItem>
                    <MenuItem value={3}>Image</MenuItem>
                </Select>
                <TextField id="name" label="Breakpoints (sm=6)" fullWidth className={classes.textField} />

                {type === 1 &&
                    <>
                        <TextField id="name" label="Button Titel" fullWidth className={classes.textField} />
                        <TextField id="name" label="Button Link" fullWidth className={classes.textField} />
                        <FormControlLabel className={classes.textField}
                            control={<Checkbox checked={""} onChange={""} name="checkedA" />}
                            label="Link in neuem Tab öffnen"
                        />


                    </>
                }
                {type === 2 &&
                    <>
                        <TextField
                            id="description"
                            label="HTML"
                            multiline
                            rows={5}
                            variant="outlined"
                            fullWidth
                            className={classes.textField}
                        />

                    </>
                }
                {type === 3 &&
                    <>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span" fullWidth className={classes.textField}
                            startIcon={<ImageIcon />}
                            >
                                Bild Hochladen
        </Button>
                        </label>

                    </>
                }

            </DialogContent>
            <DialogActions className={classes.action}>

                <div>
                    <Button onClick={onClose} color="primary">
                        Abbrechen
          </Button>
                    <Button onClick={onSubmit} color="primary">
                        Erstellen
          </Button>
                </div>
            </DialogActions>
        </Dialog>

    );
}