import React, {useEffect, useState, createRef,useRef} from "react"
import {gql, useQuery} from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Button from "@material-ui/core/Button"
import Slide from '@material-ui/core/Slide';
import {makeStyles} from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow"




const useStyles = makeStyles({
    typoContent:{
        color:"#172154",
        marginBottom:20
    },
    typoAuthor:{
        color:"#8b90a9"
    },
    slide:{
        position:"absolute",
        transition: "top 1000ms",
    },
    parent:{
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        '&-webkit-scrollbar':{
            display: "none"
        }
    }
})

const NEXT_BRAINSTORM = gql`
query getBrainstormAnswers($roomId:Int!){
        getBrainstormAnswers(roomId:$roomId){
    author,
    content,
    }
}`

export default function IdeaOnScroll() {
    //scroll event

    const classes= useStyles()
    const myRef =useRef(null);
    const [state,setState] = useState(0)

    const {loading, error, data, refetch} = useQuery(NEXT_BRAINSTORM, {
        variables: {
            roomId: 1,
        },
    })
    const [count, setCount] = useState(1);

    useEffect(() => {
        let counter = count;
        const interval = setInterval(() => {
            if (counter >= data.getBrainstormAnswers.length) {
                refetch()

            } else {
                setCount(count => count + 1);
                counter++; // local variable that this closure will see
                console.log("counter",counter)
                window.scrollTo({
                    left:0,
                    top:document.body.scrollHeight,
                    behavior:"smooth"
                })

            }
        }, 3000);
        return () => clearInterval(interval);
    }, [data]);


    const toRender =[];
    if (data) {

        const getData = data.getBrainstormAnswers;

        for(let i=0;i<count;i++){
            toRender.push({id:i,...getData[i]});
        }


        const mapData = toRender.map((getItem,i) => {
            return (
    <Grow in={true} timeout={800}>

<Paper elevation={3} key={getItem.id} style={{padding: 20, marginBottom:60}}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant={"h4"} component={"h1"} className={classes.typoContent}>{getItem.content}</Typography>
                            <Typography variant={"h5"} component={"h1"} style={{fontWeight:400}} className={classes.typoAuthor}>{getItem.author}</Typography>
                        </Grid>
                    </Paper>
    </Grow>
                        
                    
            )
        })

        return (
            <>
                  <div className={"feed"} style={{backgroundColor:"#d1d3dd", overflow:"auto",padding:150, minHeight: "100vh", marginRight: "-10px",  marginLeft: "-10px",  marginTop: "-10px",  marginBottom: "-10px"}}>
        <Container  maxWidth={"xl"}>
        {mapData}

        </Container>
        </div>

            </>
        )

    }else if(loading){
        return (<p>Daten werden geladen</p>)
    }else if(error){
        console.log(error)
    }


}
