import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from "react-router-dom";
import { datagrid } from '../../../util/language';
import DataGridToolbar from '../../DataGridToolbar';



var date = new Date(1616537403 * 1000);

const rows = [
  { id: 1, exhibitor: 'Aussteller 1', updatedAt: date ,createdAt: date, preview: "https://via.placeholder.com/200x120"  },
  { id: 2, exhibitor: 'Aussteller 2', updatedAt: date, createdAt: date, preview: "https://via.placeholder.com/200x120" },
  { id: 3, exhibitor: 'Aussteller 3', updatedAt: date, createdAt: date,preview: "https://via.placeholder.com/200x120"  },
  ];


const columns= [

  { field: "id", headerName: "ID", type: "number", hide: 'true', filterable: false },
  { field: "preview", headerName: " ", type: "string", width: 200, 
  renderCell: (params) => (
    <div style={{height: "100%", padding: 0, margin: 0, display: "flex"}}>
      <img src={params.value} style={{maxWidth: "100%"}}/>
    </div>
      
  ),
},
  { field: "exhibitor", headerName: "Aussteller", type: "string", flex: 1.5, },
  { field: "updatedAt", headerName: "Geändert", type: "dateTime", flex: 0.8, },  
  { field: "createdAt", headerName: "Angelegt", type: "dateTime", flex: 0.8, },  
  
];


export default function Exhibitors(props) {
  const [selection, setSelection] = React.useState([]);
  const history = useHistory();

  const addItem = (value) => {
    history.push("editExhibitor")

  }

  const editItem = (data) => {
    if(selection.data){
      history.push("editExhibitor?id="+selection.data.id)

    }

  }
  function currentlySelected(selections) {
    console.log(selections)
    setSelection(selections)
  }



  return (
    <>
    
        <Card>
        <CardHeader title="Aussteller"/>
      <CardContent>
        <DataGrid rowHeight={120} rows={rows} columns={columns} components={{
          Toolbar: DataGridToolbar,
        }} 
        componentsProps={{toolbar: {filter: true , remove: true, add: addItem, edit: editItem }}}
        pageSize={100}   autoHeight hideFooterRowCount='false' 
        localeText={datagrid}
        onRowSelected={currentlySelected}
        />
        
      </CardContent>
      </Card>


    </>
  )
}
