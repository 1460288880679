import { gql, useQuery } from '@apollo/client';
import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { useAuthDispatch, useAuthState } from '../../../context/auth';




export default function ProjectContextSwitch({ projectCode }) {
  const history = useHistory();
  const authDispatch = useAuthDispatch()
  const {user} = useAuthState()

  useEffect(() => {
    if(projectCode){
  authDispatch({ type: 'SET_PROJECT', payload: projectCode })
      history.push("/" + user.companyCode+ "/" +projectCode + "/")
    }
   
  }, [projectCode]);

 
  return null

}
