
import React, { createContext, useContext, useReducer } from 'react';

const PopupStateContext = createContext()
const PopupDispatchContext = createContext()

let popup = null;

const popupReducer = (state, action) => {
  //let { test } = action.payload
  switch (action.type) {

    case 'OPEN_POPUP':
      popup = action.payload

      return {
        ...state,
        popup: popup,
      }
      case 'CLOSE_POPUP':
      popup = null
  
        return {
          ...state,
          popup: popup,
        }

    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const PopupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(popupReducer, { popup })

  return (
    <PopupDispatchContext.Provider value={dispatch}>
      <PopupStateContext.Provider value={state}>
        {children}
      </PopupStateContext.Provider>
    </PopupDispatchContext.Provider>
  )
}

export const usePopupState = () => useContext(PopupStateContext)
export const usePopupDispatch = () => useContext(PopupDispatchContext)
