import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, {useState} from 'react';
import { useMutation, gql } from '@apollo/client';
import LoadingScreen from './Loading'
const CREATE_COMPANY=gql`
mutation createCompany($company: CompanyInput){
    createCompany(company: $company) {
      companyCode
      }
  }`

const useStyles = makeStyles((theme) => ({
    action: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        margin: theme.spacing(5),
    },
    switch:{
        marginTop: theme.spacing(2)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#000',
      },
    

}));



export default function FormDialog({ open, onClose , onFinish}) {

    const [errors, setErorrs] = useState(null)
    const [variables, setVariables] = useState({
        companyName: '',
        companyCode: '',
      })

    const [createCompany, { data, loading }] = useMutation(CREATE_COMPANY, {
        onError(err) {
          if (err.graphQLErrors[0]) {
            setErorrs(err.graphQLErrors[0].extensions.errors)
          }
          else {
            setErorrs("API ERROR")
          }
        },
        onCompleted(data) {
            onFinish()
        }
      });



      
    
    const classes = useStyles();

if(loading)return <LoadingScreen/>

    return (

        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <form
        onSubmit={e => {
            console.log("test")
          e.preventDefault();
        createCompany({ variables: { company: {companyCode: variables.companyCode, companyName: variables.companyName}} })

        
        }}
      >
            <DialogTitle id="form-dialog-title">Neue Organisation erstellen</DialogTitle>
            <DialogContent>
                <DialogContentText>
          </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Organisations Bezeichnung"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    onChange={e => setVariables({ ...variables, companyName: e.target.value })}
                />
                <TextField
                    margin="dense"
                    id="code"
                    label="Organisation Shortcode"
                    type="text"
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    onChange={e => setVariables({ ...variables, companyCode: e.target.value })}
                />

                  {errors && <div><br/> Bitte Felder ausfüllen</div>}        

            </DialogContent>
            <DialogActions className={classes.action}>
               
                <div>
                    <Button onClick={onClose} color="primary">
                        Abbrechen
          </Button>
                    <Button type="submit" color="primary">
                        Erstellen
          </Button>
                </div>
            </DialogActions>
        </form>

        </Dialog>
    );
}