  
import React, { createContext, useContext, useReducer } from 'react';

const NavigtationStateContext = createContext()
const NavigationDispatchContext = createContext()

let mobileOpen = false;

const navigationReducer = (state, action) => {
  //let { test } = action.payload
  switch (action.type) {    
    
    case 'TOGGLE_BURGER':
        mobileOpen=!mobileOpen

      return {
        ...state,
        mobileOpen: mobileOpen,
      }
     
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const NavigationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(navigationReducer, {  })

  return (
    <NavigationDispatchContext.Provider value={dispatch}>
      <NavigtationStateContext.Provider value={state}>
        {children}
      </NavigtationStateContext.Provider>
    </NavigationDispatchContext.Provider>
  )
}

export const useNavigationState = () => useContext(NavigtationStateContext)
export const useNavigationDispatch = () => useContext(NavigationDispatchContext)
