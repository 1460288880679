import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box"
import { useMutation, gql } from '@apollo/client';
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles({
  dialogActions:{
    display: 'flex',
    flexDirection: 'row', //oder 'row'

  },
  dialogActionsXs:{
    display: 'flex',
    flexDirection:"column-reverse",

  },
})

const EDIT_QUESTION=gql`
mutation editQandA($id: Int!,$updatedContent:String!){
  editQandA(qandaId:$id,updatedContent:$updatedContent) 
  }`

export default function EditQuestion(props) {
    const matchesButtons = useMediaQuery('(min-width:350px)')
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editQuestion] = useMutation(EDIT_QUESTION)
  const [formState, setFormState] = useState({
    updatedContent: props.value.content
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    <IconButton variant="contained" color="default" onClick={handleClickOpen}>
    <EditIcon /> Bearbeiten
    </IconButton>
    <Dialog
    fullWidth
    maxWidth={"sm"}
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">Inhalt bearbeiten</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">
    <TextField
    required
    size={"medium"}
    id="standard-multiline-static"
    multiline
    fullWidth
    rows={6}
    inputProps={{ maxLength: 500}}
    type="text"
    value={formState.updatedContent}
    onChange={
      ((e)=>{
        setFormState({
          ...formState,
          updatedContent: e.target.value
        })
      })
    }
    type="text"
    placeholder="Frage Bearbeiten"
    />
    </DialogContentText>
    </DialogContent>
    <DialogActions className={((`${matchesButtons}`=="true")? classes.dialogActions: classes.dialogActionsXs)}>
      <Box flexGrow={1}>
    <Button size="large" variant="outlined" color="primary" onClick={((e) =>{
      e.preventDefault()
      console.log(formState.updatedContent)
      editQuestion({
        variables:{
          id: props.value.id,
          updatedContent: formState.updatedContent
        }
      })
      handleClose();
    })
  } autoFocus>
  Speichern
  </Button>
        </Box>
  <Box>
  <Button size="large" variant="outlined" color="inherit"
      onClick={handleClose} >Abbrechen
  </Button>
      </Box>
  </DialogActions>
  </Dialog>
  </div>
);
}
