import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Container from "@material-ui/core/Container"
import { CompactPicker } from 'react-color';


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },mainGrid:{
    maxWidth:1200,
    border:"1px solid #d9d9d9",
    borderRadius:5,
    padding: 10
  },
  typograhizzle:{
    color: "#153243",
  },
  typograhizzleColor:{
    fontSize:20,
    fontWeight: 400
  },
  customColor:{
    border:"1px solid #d9d9d9",
    borderRadius:4,
    padding: 10
  },
});

export default function Customization(props) {
  const classes = useStyles();
  const [color, setColor] =useState({
    background:"",
    title:"",
    content:""
  })

    return(
      <div className={classes.root}>
      <Container maxWidth={"md"}>
      <Grid container className={classes.mainGrid} spacing={5}>
      <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
      <Grid item container justify="center" item xs={12} md={12} lg={12} xl={12}>
      <Typography variant="h3" component="h4" color="primary" >Koloriere deine Karte</Typography>
      </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
      <Grid item xs={12} md={4} lg={4} xl={4}>
      <Typography variant="h6" component="h4" className={classes.typograhizzle} >Hintergrund</Typography>
      </Grid>
      <Grid item alignItems="center" xs={12} md={6}lg={6} xl={6}>
      <TextField id="standard-basic" variant="outlined" fullWidth
        value={color.background} onChange={(e) =>{
        e.preventDefault()
        setColor({
          ...color,
          background: e.target.value
        })
        props.parentColorHandler({
          background: e.target.value,
          title: color.title,
          content: color.content
          })
        }}/>
      </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
      <Grid item xs={12} md={4} lg={4} xl={4}>
      <Typography variant="h6" component="h4" className={classes.typograhizzle} >Überschrift</Typography>
      </Grid>
      <Grid item alignItems="center" xs={12} md={6}lg={6} xl={6}>
      <TextField id="standard-basic"  variant="outlined" fullWidth
      value={color.title} onChange={(e) =>{
      e.preventDefault()
      setColor({
        ...color,
        title: e.target.value
      })
      props.parentColorHandler({
        background: color.background,
        title: e.target.value,
        content: color.content
        })
      }}/>
      </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
      <Grid item xs={12} md={4} lg={4} xl={4}>
      <Typography variant="h6" component="h4" className={classes.typograhizzle} >Inhalt</Typography>
      </Grid>
      <Grid item alignItems="center" xs={12} md={6}lg={6} xl={6}>
      <TextField id="standard-basic" variant="outlined" fullWidth
      value={color.content} onChange={(e) =>{
      e.preventDefault()
      setColor({
        ...color,
        content: e.target.value
      })
      props.parentColorHandler({
        background: color.background,
        title: color.title,
        content: e.target.value,
        })
      }}/>
      </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
      <Grid item xs={12} md={4} lg={4} xl={4}>
      <Typography variant="h6" component="h4" className={classes.typograhizzle} >Button</Typography>
      </Grid>
      <Grid item alignItems="center" xs={12} md={6}lg={6} xl={6}>
      <TextField id="standard-basic" placeholder="" variant="outlined" fullWidth/>
      </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
      <Grid item xs={12} md={4} lg={4} xl={4}>
      <Typography variant="h6" component="h4" className={classes.typograhizzle} >Button Inhalt</Typography>
      </Grid>
      <Grid item alignItems="center" xs={12} md={6}lg={6} xl={6}>
      <TextField id="standard-basic" placeholder="" variant="outlined" fullWidth/>
      </Grid>
      </Grid>
      </Grid>
      </Container>
      </div>
    )
  }
