import { gql, useQuery, useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from "@material-ui/core/Grid"
import Grow from '@material-ui/core/Grow'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Link } from "react-router-dom"

import Icon from '@material-ui/core/Icon';
import MaterialTable from 'material-table';
import { materialtable } from '../../util/language';
import LoadingScreen from '../dialogs/Loading';
import Surface from '../MainSurface';
import DataGridToolbar from '../DataGridToolbar';
import { DataGrid } from '@material-ui/data-grid';
import { datagrid } from '../../util/language';



const GET_Elements = gql`
query getElements{
    getElements{
        id: elementId
      status
      elementJson
    }
}`

const SET_STATUS = gql`
mutation setElementStatus($elementId: Int!, $status: String!){
  setElementStatus(elementId:$elementId,status:$status)
}`




const invert = (status)=>{
if(status =="active") return "inactive"
return "active"

}


export default function Stages(props) {

    const { error, loading, data, refetch } = useQuery(GET_Elements,
        {
          fetchPolicy: "network-only"
        })
        let elements = []
        const forceUpdate = useForceUpdate();
      
        const [openDialog, setDialogOpen] = React.useState(false);
        const [selection, setSelection] = React.useState(null);
      

        const columns= [

          { field: "elementId", headerName: "id", hide: "true", type: "int",filterable: false },
          { field: "elementJson", headerName: "Info", type: "String", flex:2, },  
          { field: "status", headerName: "status", type: "String", flex:0.5 },  
           {
            field: 'toggle',
            headerName: 'toogle status',
            width: 150,
            renderCell: (params) => (
              <strong>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 16 }}
                  onClick={()=>{
                    console.log(params)
                    let status=invert(params.row.status)
                    setStatus({variables:{elementId: params.row.id, status: status }})
                    console.log(selection)}}
                >
                  Togle
                </Button>
              </strong>
            ),
          },
          
        ];

        const [setStatus, { loading: mutationLoading, error: mutationError }] = useMutation(SET_STATUS, {
          onCompleted: (data) => {
            refetch(); 
            forceUpdate()
          },
  
          onError: (error) => { }
      })


        function useForceUpdate(){
          const [value, setValue] = React.useState(0); // integer state
          return () => setValue(value => value + 1); // update the state to force render
        }
        
        const handleAddProject = () => {
          setDialogOpen(true);
        };
        
        
        function currentlySelected(selections) {
          setSelection(selections)
        }


        if (error) return `Error! ${error}`;
      
        
        if(data){
      
          return (
            <>
        
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
               
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ widht: '100%' }}>
                  <DataGrid rows={data.getElements} columns={columns} components={{
                Toolbar: DataGridToolbar,
              }} 
              componentsProps={{toolbar: {filter: true , remove: false, add: handleAddProject, edit: false }}}
              pageSize={100}   autoHeight hideFooterRowCount='false' 
              localeText={datagrid}
              onRowSelected={currentlySelected}
              />
                  </Grid>
                </Grid>
        
              
              {(loading) && <LoadingScreen/> }
            </>
          )
        }

        return null
      
      


}

