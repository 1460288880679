import React from 'react'
import { Switch } from 'react-router-dom'
import { useAuthState } from '../../context/auth'
import DynamicRoute from '../../util/DynamicRoute'
import Companys from '../Pages/company/Companys'
import Login from '../Pages/Login'
import Project from '../Pages/project/Project'
import Projects from '../Pages/project/Projects'
import AppBar from './AppBar'
import CompanysContextSwitch from '../Pages/company/CompanyContextSwitch';
import QandA from '../Pages/qanda/QandA'
import Quiz from '../Quiz'
import Feed from '../Feed'
import Postcards from '../Postcards'


export default function Router(props) {
  const { user } = useAuthState() 

  return (
    <>

      <Switch>

        <DynamicRoute exact path="/login" guest ><Login /></DynamicRoute>
       
        <DynamicRoute exact path="/" authenticated neededRole={6}>{user && (user.roleCode<4? <Companys/>  : <Projects/>)}</DynamicRoute>
        <DynamicRoute exact path="/:company/" authenticated neededRole={5}><Projects /></DynamicRoute>   
        <DynamicRoute exact path="/:company/:project/qanda/:id" authenticated neededRole={5} ><QandA /></DynamicRoute>
        <DynamicRoute exact path="/:company/:project/feed" authenticated neededRole={5} ><Feed /></DynamicRoute>
        <DynamicRoute exact path="/:company/:project/quiz" authenticated neededRole={5} ><Quiz /></DynamicRoute>
        <DynamicRoute exact path="/:company/:project/postcards" authenticated neededRole={5} ><Postcards /></DynamicRoute>
        <DynamicRoute path="/:company/:project/" authenticated neededRole={5}><Project /></DynamicRoute>

      </Switch>
    </>
  )


}
