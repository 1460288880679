import { gql, useQuery } from '@apollo/client'
import Grid from "@material-ui/core/Grid"
import { DataGrid } from '@material-ui/data-grid'
import React from 'react'
import { datagrid } from '../util/language'
import DataGridToolbar from '../components/DataGridToolbar'
import LoadingScreen from '../components/dialogs/Loading'
import { Container } from '@material-ui/core';




const GETQUIZWINNER = gql`
query getQuizWinner{
    getQuizWinner{
        id :customerId
        customerId
       alias
    }
}`
export default function PostCardSlides(){





    const { error, loading, data } = useQuery(GETQUIZWINNER,
        {
          fetchPolicy: "network-only"
        })


        if(data && data.getQuizWinner){
            console.log(data.getQuizWinner)

let winners= data.getQuizWinner.map((element, index) =>  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ widht: '100%', textAlign: "center" }}>
                <h3>{index+1 + "  "+element.alias}</h3>

                
</Grid>

)

        return (
            <>
  <Container maxWidth="md">
        
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
               
                  {winners}
                  </Grid>
                </Grid>
        
              
              {(loading) && <LoadingScreen/> }
  </Container>

            </>
          )

            }

        return null

        }

  

