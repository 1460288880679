import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from '@material-ui/core/styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FlagIcon from '@material-ui/icons/Flag';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import SettingsIcon from '@material-ui/icons/Settings';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        background: theme.palette.background.paper
    },
    nested: {
    },
    link: {
        textDecoration: 'none',
        '&:focus, &:hover, &:visited, &:link, &:active': {
            textDecoration: 'none',
            color: '#000',
        },
    }
}));



function getItems() {

    var myItems = {
        list: [
            {
                key: 100,
                title: "",
                items: [
                    {
                        key: 1,
                        name: "Home",
                        icon: <HomeIcon />,
                        route: '',

                    },
                    {
                        key: 2,
                        name: "Quiz",
                        icon: <DashboardIcon />,
                        route: 'quiz',

                    },
                    {
                        key: 3,
                        name: "Bühne",
                        icon: <OndemandVideoIcon />,
                        route: 'stages',

                    },
                    {
                        key: 4,
                        name: "Feed",
                        icon: <FlagIcon />,
                        route: 'feed',

                    },
                    {
                        key: 5,
                        name: "Q & A",
                        icon: <LiveHelpIcon />,
                        route: 'qanda',

                    },
                    {
                        key: 6,
                        name: "Postkarten",
                        icon: <CallToActionIcon />,
                        route: 'postcards',
                    },
                    

                ]
            },
            {
                key: 200,
                title: "",
                items: [
                    {
                        key: 7,
                        name: "Teilnehmer",
                        subitems: [

                            {
                                key: 8,
                                name: "Tickets",
                                icon: <ConfirmationNumberIcon />,
                                route: 'tickets',

                            }, {
                                key: 9,
                                name: "Teilnehmer",
                                icon: <GroupIcon />,
                                route: 'attendees',

                            },

                        ]
                    },

                ]
            },
            {
                key: 300,
                title: "",
                items: [
                    {
                        key: 10,
                        name: "Erweitert",
                        subitems: [

                            {
                                key: 11,
                                name: "Sprache",
                                icon: <LanguageIcon />,
                                route: 'language',

                            }, {
                                key: 12,
                                name: "Einstellungen",
                                icon: <SettingsIcon />,
                                route: 'settings',

                            },
                            {
                                key: 13,
                                name: "Analytik",
                                icon: <AssessmentIcon />,
                                route: 'analytics',

                            },

                        ]
                    },

                ]
            }
        ]
    };
    return myItems;
}


export default function Project(props) {
    const [open, setOpen] = useState(null);
    const items = getItems();
    const classes = useStyles();
  
    return (
        <div>

            {items.list.map(list =>
            (
                <List
                    className={classes.root}
                    key={list.key}
                    subheader={
                        <ListSubheader>{list.title}</ListSubheader>
                    }
                >
                    {list.items.map(item =>
                    (
                        <div key={item.key}>
                            {item.subitems != null ? (
                                <div key={item.key}>
                                    <ListItem
                                        button
                                        key={item.key + 100}
                                        onClick={() => setOpen((item.key === open) ? null : item.key
                                        )}
                                    >
                                        {item.icon ? <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon> : <></>}

                                        <ListItemText
                                            primary={item.name}
                                        />
                                        {open === item.key ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )}
                                    </ListItem>
                                    <Collapse
                                        key={item.key}
                                        component="li"
                                        in={(open === item.key) ? true : false}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List disablePadding>
                                            {item.subitems.map(
                                                sitem =>
                                                (
                                                    <Link key={sitem.key}
                                                        className={classes.link}
                                                        to={sitem.route}>
                                                        <ListItem

                                                            key={
                                                                sitem.key
                                                            }
                                                            className={
                                                                classes.nested
                                                            }
                                                        >
                                                            <ListItemIcon>
                                                                {sitem.icon}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                key={
                                                                    sitem.key
                                                                }
                                                                primary={
                                                                    sitem.name
                                                                }
                                                            />
                                                        </ListItem>
                                                    </Link>
                                                )

                                            )}
                                        </List>
                                    </Collapse>{" "}
                                </div>
                            ) : (
                                <Link key={item.key}
                                    className={classes.link}
                                    to={item.route}>
                                    <ListItem
                                        button
                                        onClick={() => setOpen(item.key)}
                                        key={item.key}
                                    >
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.name}
                                        />
                                    </ListItem>
                                </Link>
                            )}
                        </div>
                    )
                    )}
                    <Divider key={list.key} absolute />
                </List>
            )
            )}
        </div>
    );
}

