import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { datagrid } from '../../../util/language';
import DataGridToolbar from '../../DataGridToolbar';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ImageIcon from '@material-ui/icons/Image';
import Section from './Section'
import DialogCreateAsset from '../../dialogs/DialogCreateAsset'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  titleImage:{
    widht: "100%",
    display: "flex",
    padding: theme.spacing(3)
  }
}));

const mySections = []


export default function EditExhibitors({exhibitorId}) {
  const classes = useStyles();
  const [sections, setSections] = React.useState(mySections);
  
  const [query, setQuery] = React.useState('');
 
  const handleQuery = data => {
    setQuery(data);    
  };


  useEffect(() => {
  });





  const history = useHistory();

  const saveExhibitor = (value) => {

  }



  const addSection = () => {
    mySections.push({text: "test"})
    const newSections = [...mySections]
    setSections(newSections)
  }


  return (
    <>
      <Container>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card elevation={3}>
              <CardHeader title="Info" />
              <CardContent>
                <TextField id="name" label="Bezeichnung" fullWidth className={classes.textField} />
                <TextField
                  id="description"
                  label="Beschreibung"
                  multiline
                  rows={3}
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                />
                <TextField id="bgColor" label="Hintergrund Farbe" fullWidth className={classes.textField} />

                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span" startIcon={<ImageIcon />}>
                    Titelbild Hochladen
        </Button>
                </label>


              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card elevation={3}>
              <CardHeader title="Inhalte" />
              <CardContent>
                <div className={classes.titleImage}> 
                <img src="https://via.placeholder.com/1920x576" style={{maxWidth: "100%"}}/>

                </div>
                {sections.map((section, index) => (
                  <Section key={index} section={section} query={query} handleQuery={handleQuery}/>
                ))}

                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  <Fab color="primary" aria-label="add" onClick={addSection}>
                    <AddIcon />
                  </Fab>
                </div>

              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>

    </>
  )
}
