import React,{useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {ChooseOption1, ChooseOption2, ChooseOption3} from "./ChooseCards"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import SplitButton from './Createcarddropdown'




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainGrid:{
    maxWidth:1200,
    border:"1px solid #d9d9d9",
    borderRadius:5,
    padding: 10
  },
  typograhizzle:{
    color: "#153243"
  },
  containerCards:{
  },
  buttonOptions:{
    border: "4px solid grey",
    padding:0
  }
}));

export default function CreateCard(props) {
  const classes = useStyles();
  const cardOption = props.cardOption
  const [border, setBorder] = useState()
  const [state, setState] = useState({
    name: '',
    title: '',
    content:"",
    buttonText:"",
    icon:"",
    landingPage:""
  })

  return (
    <div className={classes.root}>
    <Container maxWidth={"md"}>
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} className={classes.mainGrid}>
    <Grid container justify="center" item xs={12}>
    <Typography variant="h3" component="h1" color="primary">Design it yourself</Typography>
    </Grid>
    <hr/>
    <Grid item xs={12}>
    <Typography variant="h6" component="h4" className={classes.typograhizzle}>Wähle deine Karte</Typography>
    </Grid>
    <Grid
    className={classes.containerCards}
    container direction="row" justify="space-around" item xs={12} sm={12} md={12} lg={12} xl={12} >
    <Grid
    item sm={4} md={4} lg={4} xl={4}>
    <Button onClick={(e)=>{
      e.preventDefault()
      props.parentOptionHandler({
        ...cardOption,
        option: "first"
      })
    }}><ChooseOption1/></Button>
    </Grid>
    <Grid
    item sm={4} md={4} lg={4} xl={4}>
    <Button  onClick={(e)=>{
      e.preventDefault()
      props.parentOptionHandler({
        ...cardOption,
        option: "second"
      })
    }}><ChooseOption2/></Button>
    </Grid>
    <Grid
    item sm={4} md={4} lg={4} xl={4}>
    <Button
    onClick={(e)=>{
      e.preventDefault()
      props.parentOptionHandler({
        ...cardOption,
        option: "third"
      })

    }}><ChooseOption3/></Button>
    </Grid>
    </Grid>
    <hr/>
    <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
    <Grid item xs={12} md={4} lg={4} xl={4}>
    <Typography variant="h6" component="h4" className={classes.typograhizzle} >Name der Karte</Typography>
    </Grid>
    <Grid item alignItems="center" xs={12} md={6}lg={6} xl={6}>
    <TextField id="standard-basic" placeholder="Die beste Karte" variant="outlined" fullWidth
    value={state.name} onChange={(e) =>{
      e.preventDefault()
      setState({
        ...state,
        name: e.target.value
      })
      props.parentHandleChange({
        name:e.target.value,
        title:state.title,
        content:state.content,
        buttonText:state.buttonText,
        landingPage:state.landingPage})
      }}/>


      </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
      <Grid item xs={12} md={4}  lg={4} xl={4}>
      <Typography variant="h6" component="h4" className={classes.typograhizzle}>Überschrift</Typography>
      </Grid>
      <Grid item xs={12} md={6}lg={6} xl={6}>
      <TextField id="standard-basic" placeholder="Beispiel" variant="outlined" fullWidth
      value={state.title} onChange={(e) =>{
        e.preventDefault()
        setState({
          ...state,
          title: e.target.value
        })
        props.parentHandleChange({
          name:state.name,
          title:e.target.value,
          content:state.content,
          buttonText:state.buttonText,
          landingPage:state.landingPage})
        }}/>
        </Grid>
        </Grid>
        <Grid container alignItems="baseline" item xs={12} lg={12} xl={12}>
        <Grid item xs={12} md={4}  lg={4} xl={4}>
        <Typography variant="h6" component="h4" className={classes.typograhizzle}>Inhalt</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6}>
        <TextField id="standard-basic" placeholder="Beispiel Inhalt"multiline
        rows={4} variant="outlined" fullWidth
        value={state.content} onChange={(e) =>{
          e.preventDefault()
          setState({
            ...state,
            content: e.target.value
          })
          props.parentHandleChange({
            name:state.name,
            title:state.title,
            content:e.target.value,
            buttonText:state.buttonText,
            landingPage:state.landingPage})
          }}
          />
          </Grid>
          </Grid>
          <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
          <Grid item xs={12} md={4}  lg={4} xl={4}>
          <Typography variant="h6" component="h4" className={classes.typograhizzle}>Button Text</Typography>
          </Grid>
          <Grid item xs={12} md={6}lg={6} xl={6}>
          <TextField id="standard-basic" placeholder="Speichern" variant="outlined" fullWidth
          value={state.buttonText} onChange={(e) =>{
            e.preventDefault()
            setState({
              ...state,
              buttonText: e.target.value
            })
            props.parentHandleChange({
              name:state.name,
              title:state.title,
              content:state.content,
              buttonText:e.target.value,
              landingPage:state.landingPage})
            }}/>
            </Grid>
            </Grid>
            <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
            <Grid item xs={12} md={4}  lg={4} xl={4}>
            <Typography variant="h6" component="h4"className={classes.typograhizzle}>Bild</Typography>
            </Grid>
            <Grid item xs={12} md={4}lg={4} xl={4}>
            <SplitButton/>
            </Grid>
            <Grid item xs={12} md={4}lg={4} xl={4}>
            <Button size="large" color="primary" variant="contained" aria-label="upload picture" component="span">
            <PhotoCamera />&nbsp;Image
            </Button>
            </Grid>
            </Grid>
            <Grid container alignItems="center" item xs={12} lg={12} xl={12}>
            <Grid item xs={12} md={4} lg={4} xl={4}>
            <Typography variant="h6" component="h4"className={classes.typograhizzle}>Landing Page</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
            <TextField id="standard-basic" placeholder="https://www.wirkung-digital.de" variant="outlined" fullWidth
            value={state.landingPage} onChange={(e) =>{
              e.preventDefault()
              setState({
                ...state,
                landingPage: e.target.value
              })
              props.parentHandleChange({
                name:state.name,
                title:state.title,
                content:state.content,
                buttonText:state.buttonText,
                landingPage:e.target.value})
              }}/>
              </Grid>
              </Grid>
              </Grid>
              </Container>
              </div>
            );
          }
