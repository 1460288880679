import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';



export default function Projects(props) {

  return (
    <>
        
      
    
    <Card>
    <CardHeader title="Projekt Übersicht"/>

  <CardContent>

          <h4>Teilnehmer: 1942</h4>
          <h4>Conversions: 587</h4>
          <h4>Anzahl Aussteller: 12</h4>
          <h4>Anzahl Bühnen: 4</h4>
  </CardContent>

  </Card>

    </>
  )
}
