import {
  ApolloClient,
  ApolloProvider as Provider,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React from 'react';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from "@apollo/client/link/error";




let httpLink = createUploadLink({
  uri: 'https://gls-admin.wirkung.stream/graphql/',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

httpLink =  authLink.concat(httpLink)






const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  watchQuery: {
    fetchPolicy: 'network-only',
  },  
})



export default function ApolloProvider(props) {
  return <Provider client={client} {...props} />
}
