import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { gql, useMutation } from '@apollo/client';




const RESETPOLL = gql`
  mutation resetPoll($pollId: Int!) {
    resetPoll(pollId:$pollId)
  }
`;

const RESETQANDA = gql`
  mutation resetQandA($roomId: Int!) {
    resetQandA(roomId:$roomId)
  }
`;
const RESTQUIZ = gql`
  mutation resetQuiz {
    resetQuiz
  }
`;
const RESETPOSTCARDS = gql`
  mutation resetPostcards {
    resetPostcards
  }
`;
const RESTSELFIE = gql`
  mutation resetSelfie {
    resetSelfie
  }
`;



export default function Projects(props) {

  const [resetPoll, poll] = useMutation(RESETPOLL);
  const [resetQandA, qanda] = useMutation(RESETQANDA);
  const [resetQuiz, quiz] = useMutation(RESTQUIZ);
  const [resetPostcards, postcard] = useMutation(RESETPOSTCARDS);
  const [resetSelfie, selfie] = useMutation(RESTSELFIE);

  const [selection, setSelection] = useState(null)
  const [open, setOpen] = React.useState(false);


  const handleMake = () => {
    setOpen(false);
    switch (selection) {
      case "Frage einreichen leeren":
        resetQandA({ variables: { roomId: 2 } })
        break;
      case "Brainstorming leeren":
        resetQandA({ variables: { roomId: 1 } })
        break;
      case "Abstimmung Songs 1 leeren":
        resetPoll({ variables: { pollId: 3 } })
        break;
      case "Abstimmung Songs 2 leeren":
        resetPoll({ variables: { pollId: 4 } })
        break;
      case "Abstimmung Songs 3 leeren":
        resetPoll({ variables: { pollId: 5 } })
        break;
      case "Abstimmung Stimmung leeren":
        resetPoll({ variables: { pollId: 2 } })
        break;
      case "Quiz leeren":
        resetQuiz()
        break;
      case "Postkarten leeren":
        resetPostcards()
        break;
      case "Selfies zurücksetzen":
        resetSelfie()
        break;
      default:
        break

    }

  };


  const handleClose = () => {
    setOpen(false);

  };

  return (
    <>



      <Card>
        <CardHeader title="Projekt Übersicht" />

        <CardContent>

          <Grid container spacing={5}>

            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Frage einreichen leeren"); setOpen(true) }}
              >
                Frage Einreichen leeren
      </Button>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Brainstorming leeren"); setOpen(true) }}
              >
                Brainstorming leeren
      </Button>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Abstimmung Songs 1 leeren"); setOpen(true) }}
              >
                Abstimmung Songs 1 leeren
      </Button>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Abstimmung Songs 2 leeren"); setOpen(true) }}
              >
                Abstimmung Songs 2 leeren
      </Button>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Abstimmung Songs 3 leeren"); setOpen(true) }}
              >
                Abstimmung Songs 3 leeren
      </Button>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Abstimmung Stimmung leeren"); setOpen(true) }}
              >
                Abstimmung Stimmung leeren
      </Button>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Quiz leeren"); setOpen(true) }}
              >
                Quiz leeren
      </Button>
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Postkarten leeren"); setOpen(true) }}
              >
                Postkarten leeren
      </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary"
                onClick={() => { setSelection("Selfies zurücksetzen"); setOpen(true) }}
              >
                Selfies zurücksetzen
      </Button>
            </Grid>
          </Grid>



        </CardContent>

      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{selection}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, dass sie diese schwerwiegende funktion ausführen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleMake} color="primary" autoFocus>
            Ausführen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
