
import { gql, useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useAuthDispatch } from '../context/auth';


const LOGIN_USER = gql`
  query login($email: String!, $password: String!){
    login(email: $email, password: $password){
      userId, role, roleCode, token 
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 1,
  },
  grid: {
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridElement: {
    width: '100%'
  },

  button: {
    width: '80%',
  },

}));



export default function Login(props) {
  const classes = useStyles();
  const [errors, setErorrs] = useState({})
  const authDispatch = useAuthDispatch()


  const submitLoginForm = e => {
    e.preventDefault()
    loginUser({ variables })

  }

  const [variables, setVariables] = useState({
    email: '',
    password: '',
  })

  const [loginUser, { loading }] = useLazyQuery(LOGIN_USER, {
    onError(err) {
      if (err.graphQLErrors[0]) {
        setErorrs(err.graphQLErrors[0].extensions.errors)
      }
      else {
        setErorrs("API ERROR")
      }
    },
    onCompleted(data) {
      authDispatch({ type: 'LOGIN', payload: data.login })
           window.location.href = '/'

    }
  });



  return (
<Paper className={classes.paper} elevation={5}>
            <form onSubmit={submitLoginForm} autoComplete="off" className={classes.form}>
              <Grid container direction={"column"} spacing={3}>
                <Grid item>
                  <TextField autoComplete="username" className={classes.gridElement} id="email" type="email" label="E-Mail-Adresse" onChange={e => setVariables({ ...variables, email: e.target.value })} required />
                </Grid>
                <Grid item>
                  <TextField autoComplete="current-password" className={classes.gridElement} id="password" type="password" label="Passwort" onChange={e => setVariables({ ...variables, password: e.target.value })} required />
                </Grid>
                <Grid item>
                  <Button  className={classes.gridElement} variant="contained" type="submit">Einloggen</Button>
                  <Typography style={{ color: '#8f0000' }}>&nbsp;{(errors === "API ERROR") && 'Service vorübergehend nicht erreichbar.. '}{(errors === "Ungültige Zugangsdaten") && 'Ungültige Zugangsdaten'} {errors.data}&nbsp;</Typography>
                </Grid>
              </Grid>
            </form>
          </Paper>
          

  )
}



