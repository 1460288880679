import React from 'react';
import { Switch } from 'react-router';
import DynamicRoute from '../../../util/DynamicRoute';
import Drawer from '../../Navigation/Drawer';
import ConversionCard from '../cta/conversioncardcomponents/ConversionCard';
import Tickets from '../Tickets';
import Attendees from '../Attendees';
import Exhibitors from '../exhibitors/Exhibitors';
import EditExhibitor from '../exhibitors/EditExhibitor';
import Overview from '../Overview';
import QandAs from '../qanda/QandAs';
import Stages from '../Stages';
import Surface from './ProjectSurface';
import AppBar from '../../Navigation/AppBar'
import Settings from '../Settings'


export default function Project(props) {

  return (
    <>
      <AppBar />

      <Drawer />
      <Surface>
        <Switch>
        <DynamicRoute path="/:company/:project/stages/" authenticated neededRole={5} ><Stages /></DynamicRoute>
        <DynamicRoute path="/:company/:project/exhibitors" authenticated neededRole={5} ><Exhibitors /></DynamicRoute>
        <DynamicRoute exact path="/:company/:project/qanda/" authenticated neededRole={5} ><QandAs /></DynamicRoute>
        <DynamicRoute path="/:company/:project/cta" authenticated neededRole={5} ><ConversionCard /></DynamicRoute>
        <DynamicRoute path="/:company/:project/tickets" authenticated neededRole={4} ><Tickets /></DynamicRoute>
        <DynamicRoute path="/:company/:project/attendees" authenticated neededRole={4} ><Attendees /></DynamicRoute>
        <DynamicRoute path="/:company/:project/editExhibitor" authenticated neededRole={5}><EditExhibitor /></DynamicRoute>
        <DynamicRoute path="/:company/:project/settings" authenticated neededRole={5}><Settings /></DynamicRoute>
        <DynamicRoute path="/:company/:project/" authenticated neededRole={5}><Overview /></DynamicRoute>
        </Switch>
      </Surface>      

    </>
  )

}
