import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from "react-router-dom";
import { datagrid } from '../../../util/language';
import DataGridToolbar from '../../DataGridToolbar';
import { makeStyles } from '@material-ui/core/styles';
import DialogCreateAsset from '../../dialogs/DialogCreateAsset'
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const actions = [
    { icon: <TextFieldsIcon />, name: 'Text' },
    { icon: <ImageIcon />, name: 'Bild' },
    { icon: <LinkIcon />, name: 'Button' },
    { icon: <CallToActionIcon />, name: 'Karte' },
  ];

const useStyles = makeStyles((theme) => ({
   section:{
       margin: theme.spacing(3),
       display:"flex", 
       justifyContent: "center",
       border: "2px solid",
       borderColor: theme.palette.primary1Color,
       padding: "10px"
   }
  }));

export default function Section({ query, handleQuery, section}) {
  const [openDialog, setDialogOpen] = React.useState(false);

    const closeDialog = () => {
        setDialogOpen(false);
      };
      const openNewDialog = () => {
        setDialogOpen(true);
      };
 
      const handleSubmit = () => {
        setDialogOpen(false);
        console.log(section)
        handleQuery(section.text)
      };
    
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
      };
    
      const handleOpen = () => {
        setOpen(true);
      };




  return (
    <>
    
    <div className={classes.section} >
                   
                  <SpeedDial
                  ButtonProps={{ color: "secondary" }}
                            ariaLabel="SpeedDial example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={handleClose}
            />
          ))}
        </SpeedDial>
                </div>
      <DialogCreateAsset open={openDialog} onClose={closeDialog} onSubmit={handleSubmit} />

    </>
  )
}
