import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { datagrid } from '../../util/language';
import DataGridToolbar from '../DataGridToolbar';






var date = new Date(1616537403 * 1000);

const rows = [
  { id: 1, email: 'pe@wirkung-digital.de', firstName: 'Phil', lastName: 'Emanuel', ticket: 'Silber'  },
  { id: 2, email: 'sm@wirkung-digital.de', firstName: 'Simon', lastName: 'Michaelis', ticket: 'Silber'},
  { id: 3, email: 'is@wirkung-digital.de', firstName: 'Ilya', lastName: 'Schmitt', ticket: 'Gold' },
  ];



const columns= [

  { field: "id", headerName: "ID", type: "number", hide: 'true', filterable: false },
  { field: "email", headerName: "E-Mail Adresse", type: "string", flex: 1.5, },
  { field: "firstName", headerName: "Vorname", type: "string", flex: 0.5, },
  { field: "lastName", headerName: "Nachname", type: "string", flex: 0.5, },
  { field: "ticket", headerName: "Ticket", type: "string", flex: 1, },    
];


export default function LoginPage(props) {
  useEffect(() => {
  }, []);




  return (
    <>
    
        <Card>
        <CardHeader title="Teilnehmer Verwalten"/>

      <CardContent>
      <DataGrid rowHeight={30} rows={rows} columns={columns} components={{
          Toolbar: DataGridToolbar,
        }} 
        componentsProps={{toolbar: {filter: true , remove: true, edit: true }}}
        pageSize={100}  checkboxSelection disableSelectionOnClick autoHeight hideFooterRowCount='false' 
        localeText={datagrid}
        />
        
      </CardContent>

      </Card>

    </>
  )
}
