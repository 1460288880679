import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import LoginForm from '../LoginForm';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const TITLE = 'Login - Wirkung Plattform'


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '10vh'
  },

}));

export default function LoginPage(props) {
  const classes = useStyles();

  useEffect(() => {
  }, []);



  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Container>
        <Grid
          className={classes.root}
          container
          spacing={0}
          justify="center"
          direction="row"
        >
          <Grid item xs={12} sm={6} md={5} lg={5} xl={3} style={{ backgroundColor: 'yellow' }}>
            <LoginForm />
          </Grid>
        </Grid>
      </Container>

    </>
  )
}
