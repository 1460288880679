import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { useMutation, gql } from '@apollo/client';
import useMediaQuery from "@material-ui/core/useMediaQuery"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";


const useStyles = makeStyles({
  dialogActions:{
    display: 'flex',
    flexDirection: 'row', //oder 'row'

  },
  dialogActionsXs:{
    display: 'flex',
    flexDirection:"column-reverse",
  },
  buttonMain:{
    width: 191,
    height: 68
  }
})



const ADD_QUESTION = gql`
mutation addQandA($from: String!, $roomId: Int!, $content: String!, $status:String!) {
  addQandA(author: $from roomId: $roomId ,content: $content, status: $status) 
}`

export default function OpenAddQuesiton() {
  let { id } = useParams();

  const matchesButtons = useMediaQuery('(min-width:350px)')
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [addQuestion] = useMutation(ADD_QUESTION, {variables: {roomId: parseInt(id)}})
  const [formState, setFormState] = useState({
    from: 'Moderation',
    content: ''
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {

    setOpen(false);
  };
  return (
    <div>
    <Button className={classes.buttonMain}
    size="large" variant="outlined" color="inherit"
    onClick={handleClickOpen}>
    Inhalt hinzufügen
    </Button>
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth={"sm"}
    fullWidth
    >
    <DialogTitle id="alert-dialog-title">Inhalt hinzfügen</DialogTitle>
    <DialogContent>

    <DialogContentText id="alert-dialog-description">

    <TextField
    id="standard-multiline-static"
    required
    multiline
    fullWidth
    maxRows={2}
    inputProps={{ maxLength: 50}}
    value={formState.from}
    onChange={(e) =>
      setFormState({
        ...formState,from: e.target.value
      })
    }
    type="text"
    placeholder="Autor"
    />
    </DialogContentText>
    </DialogContent>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">
    <TextField className={classes.inputQuestion}
    id="standard-multiline-static"
    required
    multiline
    fullWidth
    rows={6}
    value={formState.content}
    onChange={(e) =>
        setFormState({
          ...formState,
          content: e.target.value
        })
    }
    type="text"
    placeholder="Inhalt"
    inputProps={{maxLength: 500}}
    />
    </DialogContentText>
    </DialogContent>
    <DialogActions className={((`${matchesButtons}`=="true")? classes.dialogActions: classes.dialogActionsXs)}>
    <Box flexGrow={1} >
    <Button
    size="large" variant="outlined" color="inherit"
    onClick={((e) => {
      e.preventDefault();
      addQuestion({
        variables:{
          from: formState.from,
          content: formState.content,
          status: "PINNED"
        }
      });
      handleClose()
    })} color="primary" autoFocus>
    Speichern
    </Button>
    </Box>
    <Box>
    <Button size="large" variant="outlined" color="inherit"
        onClick={handleClose} >Abbrechen
    </Button>
        </Box>
    </DialogActions>
    </Dialog>
    </div>
  );
}
